import BackendService from './BackendService';
const singleName = 'addon';
const pluralName = singleName+'s';

const testItems = {data:{
    addons: [
        {
            _id: '61573898ff096a00114d7343',
            status: true,
            get_service:false,
            service: false,
            arrive: false,
            departure: false,
            minimum_nights: false,
            package: false,
            order: 1,
            nights: 0,
            image: 'https://res.cloudinary.com/dsfnebv62/image/upload/v1633106071/ep16sfkx8s9axow0mgez.jpg',
            price_type: 1,
            from: '2021-12-04T00:00:00.000Z',
            to:'2021-12-18T00:00:00.000Z',

            es:{
                name: 'Masaje Spa',
                description: 'Masaje corporal 30 minutos',
            },
            en:{
                name: 'Masaje Spa',
                description: 'Masaje corporal 30 minutos',
            }

        },
        {
            _id: '61573898ff096a00114d7344',
            status: true,
            get_service:false,
            service: false,
            arrive: false,
            departure: false,
            package: false,
            order: 2,
            minimum_nights: false,
            nights: 0,
            image: 'https://res.cloudinary.com/dsfnebv62/image/upload/v1633106071/ep16sfkx8s9axow0mgez.jpg',
            price_type: 1,
            from: '2021-12-04T00:00:00.000Z',
            to:'2021-12-18T00:00:00.000Z',

            es:{
                name: 'Coctel de bienvenida',
                description: 'Masaje corporal 30 minutos',
            },
            en:{
                name: 'Coctel de bienvenida',
                description: 'Body massage 30 minutos',
            }

        },

    ]
    }}


export default class AddonService extends BackendService {

    constructor() {
        super();
    }
    testItems = function(){
        return testItems;
    }

    create(data) {


        //console.log('esto se envio::',  data);
        //return data;
        return this.httpCall(singleName,{
            method: 'POST',
            data: data,
        });

    }

    createFileImage(data, urlType) {
        return this.httpCall(singleName+urlType,{
            method: 'POST',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    deleteFileImage(pathFile) {
        return this.httpCall(singleName+'-remove-file',{
            method: 'POST',
            data: {
                pathFile: pathFile            
            }
        });
    }

    update(data) {
       //console.log('contrato, con: ', contract)
        return this.httpCall(singleName+'/'+data._id,{
            method: 'PUT',
            data: data,
        });
    }
    toggleStatus(_id){
        return this.httpCall(singleName+'/'+_id+'/toggle',{
            method: 'PUT',
        });
    }

    delete(rateConfig_id) {
        return this.httpCall(singleName+'/'+rateConfig_id,{
            method: 'delete'
        });
    }

    getById(rule_id) {

        return this.httpCall(singleName+'/'+rule_id);

        //return this.httpCall('property' + property_id + '/users');
    }

    getList(params) {
        //console.log('get rooms');

        //return testItems;
        return this.httpCall(pluralName,{
            params: params,
        });
    }
    getTotalAddons(params) {
        return this.httpCall(pluralName+'/gettotal',{
            params: params,
        });
    }
    reorder(data){
        return this.httpCall(pluralName+'/reordenar',{
            method: 'post',
            data: data
        });
    }


}
