<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{info_page.header}}</h4>
                <breadcrumb>
                    <breadcrumb-item v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <card  v-loading="screenLoading"
                       element-loading-lock="true"
                       :element-loading-text="screenText"
                       element-loading-customClass="loading-large"
                       element-loading-spinner="el-icon-loading">
                    <div>
                        <div class="col-sm-12">
                            <div class="box-inventory landings">






                                <div class="form-updates w-border pb-3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle mb-0 mt-3">Información general</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="group-item mt-3">
                                                <el-tabs v-model="info_page.tab_lang">




                                                    <el-tab-pane :label="itemLang.text" :name="itemLang.lang" v-for="(itemLang, keyLang ) in info_page.langs"><div class="group-item mt-3">
                                                        <label>{{$t(info_page.lang_file+'.form.name')}}</label>
                                                        <fg-input type="text"
                                                                  style="width: 100%"
                                                                  placeholder=""
                                                                  :name="$t(info_page.lang_file+'.form.name')+' ('+itemLang.lang+')'"
                                                                  v-model="objectCrud.name[keyLang].text">
                                                        </fg-input>
                                                    </div>
                                                        <div class="group-item mt-3">
                                                            <label>{{$t(info_page.lang_file+'.form.description')}}</label>
                                                            <el-input type="textarea"
                                                                      style="width: 100%"
                                                                      :autosize="{ minRows: 4, maxRows: 8}"
                                                                      placeholder=""
                                                                      rows="8"
                                                                      :name="$t(info_page.lang_file+'.form.description')+' ('+itemLang.lang+')'"
                                                                      v-model="objectCrud.description[keyLang].text">
                                                            </el-input>
                                                        </div>
                                                    </el-tab-pane>
                                                   <!-- <el-tab-pane label="Inglés" name="en">
                                                        <div class="group-item mt-3">
                                                            <label>{{$t(info_page.lang_file+'.form.name')}}</label>
                                                            <el-input type="text"
                                                                      style="width: 100%"
                                                                      placeholder=""
                                                                      :name="$t(info_page.lang_file+'.form.name')+' (Inglés)'"
                                                                      v-model="objectCrud.en.name">
                                                            </el-input>
                                                        </div>
                                                        <div class="group-item mt-3">
                                                            <label>{{$t(info_page.lang_file+'.form.description')}}</label>
                                                            <el-input type="textarea"
                                                                      style="width: 100%"
                                                                      placeholder=""
                                                                      :autosize="{ minRows: 4, maxRows: 8}"
                                                                      rows="8"
                                                                      :name="$t(info_page.lang_file+'.form.description')+'_en'"
                                                                      v-model="objectCrud.en.description">
                                                            </el-input>
                                                        </div>
                                                    </el-tab-pane>-->
                                                </el-tabs>



                                                <label class="text-danger" v-if="errores.name">Es necesario completar el campo nombre</label>

                                                <label class="text-danger" v-if="errores.description">Es necesario completar el campo descripción</label>

                                            </div>

                                        </div>
                                        <div class="col-4">
                                            <div class="group-item  mbis-0 mt-3">
                                                <label>{{$t(info_page.lang_file+'.form.image')}}</label>
                                                <el-upload
                                                        class="upload-demo"
                                                        drag
                                                        action="#"
                                                        accept="image/jpeg,image/gif,image/png"
                                                        :on-preview="handlePictureCardPreview"
                                                        :auto-upload="true"
                                                        :http-request="addAttachment"
                                                        :on-remove="handleRemove"
                                                        :show-file-list="false"
                                                        :file-list="objectCrud.image">
                                                    <img v-if="dialogImageUrl" :src="dialogImageUrl" class="avatar">
                                                    <i class="el-icon-upload" v-else></i>
                                                    <div class="el-upload__text">Suelta tu imagen aquí o <em>haz clic para cargar</em></div>
                                                    <div slot="tip" class="el-upload__tip mt-0"> <label class="text-danger" v-if="errores.image">El campo Imagen es requerido</label></div>
                                                    <div slot="tip" class="el-upload__tip"><i class="fa fa-exclamation-circle"></i>Solo imagenes jpg/png con un tamaño menor de 500kb</div>
                                                </el-upload>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div class="form-updates w-border ">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t(info_page.lang_file+'.form.title_enchanced')}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="group-item">
                                                <el-switch
                                                        v-model="objectCrud.service"
                                                        active-color="#13ce66">
                                                </el-switch>
                                                <label class="ml-3 auto-width">{{$t(info_page.lang_file+'.form.service')}}</label>
                                            </div>
                                            <div class="group-item">
                                                <el-switch
                                                        v-model="objectCrud.get_service"
                                                        active-color="#13ce66">
                                                </el-switch>
                                                <label class="ml-3 auto-width">{{$t(info_page.lang_file+'.form.get_service')}}</label>
                                            </div>
                                           <!-- <div class="group-item">
                                                <el-switch
                                                        v-model="objectCrud.package"
                                                        active-color="#13ce66">
                                                </el-switch>
                                                <label class="ml-3 auto-width">{{$t(info_page.lang_file+'.form.package')}}</label>
                                            </div>
                                            <div class="group-item">
                                                <el-switch
                                                        v-model="objectCrud.option_package"
                                                        active-color="#13ce66">
                                                </el-switch>
                                                <label class="ml-3 auto-width">{{$t(info_page.lang_file+'.form.option_package')}}</label>
                                            </div>-->
                                        </div>
                                        <div class="col-4">
                                            <div class="group-item">
                                                <el-switch
                                                        v-model="objectCrud.arrive"
                                                        active-color="#13ce66">
                                                </el-switch>
                                                <label class="ml-3 auto-width">{{$t(info_page.lang_file+'.form.arrive')}}</label>
                                            </div>
                                            <div class="group-item">
                                                <el-switch
                                                        v-model="objectCrud.departure"
                                                        active-color="#13ce66">
                                                </el-switch>
                                                <label class="ml-3 auto-width">{{$t(info_page.lang_file+'.form.departure')}}</label>
                                            </div>
                                            <div class="group-item">
                                                <el-switch
                                                        v-model="objectCrud.minimum_nights"
                                                        active-color="#13ce66">
                                                </el-switch>
                                                <label class="ml-3 auto-width">{{$t(info_page.lang_file+'.form.minimum_nights')}}</label>


                                            </div>
                                            <div class="group-item" v-if="objectCrud.minimum_nights">

                                                <el-select class="select-default" size="large"
                                                           style="width: 60px; display: inline-block; height: 30px"
                                                           v-model="objectCrud.nights">
                                                    <el-option v-for="x in 10" class="select-default" :value="x"
                                                               :label="x" >
                                                    </el-option>
                                                </el-select>
                                                <label class="ml-3 auto-width" >{{$t(info_page.lang_file+'.form.nights')}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-updates w-border">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">{{$t(info_page.lang_file+'.form.detail_price')}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="group-item mt-3">
                                                <label>{{$t(info_page.lang_file+'.form.sales_policy')}}</label>
                                                <el-select class="select-default" size="large"
                                                           style="width: 100%"
                                                           v-model="objectCrud.price_type">
                                                    <el-option v-for="item in info_page.price_types" class="select-default" :value="item._id"
                                                               :label="item.name" >
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="group-item  custom-error mbis-0 mt-3">
                                                <label>{{$t(info_page.lang_file+'.form.vigency')}}</label>
                                                <fg-input style="width: 100%" >
                                                    <el-date-picker v-model="objectCrud.vigency" type="daterange"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1"
                                                                    range-separator="a"
                                                                    start-placeholder="Fecha inicio"
                                                                    end-placeholder="Fecha fin"
                                                                    value-format="yyyy-MM-dd"
                                                                    @change="checkCalendar"
                                                                    :default-time="['00:00:00', '00:00:00']"
                                                    >
                                                    </el-date-picker>
                                                </fg-input>
                                                <label class="text-danger" v-if="errores.vigency">El campo Vigencia es requerido</label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="group-item custom-error mbis-0 mt-3">
                                                <label>{{$t(info_page.lang_file+'.form.qty')}}</label>
                                                <el-input type="text"
                                                          style="width: 100%"
                                                          placeholder=""
                                                          :name="$t(info_page.lang_file+'.form.qty')"
                                                          v-model="objectCrud.qty">
                                                </el-input>

                                                <label class="text-danger" v-if="errores.qty">El campo inventario es requerido</label>

                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-12">
                                            <div class="box-rates mt-3">
                                                <div class="box-headers">
                                                    <div class="item-line">
                                                        <div class="item-1-8">

                                                        </div>
                                                        <div class="item-1-8">
                                                            Aplicar a todos
                                                        </div>
                                                        <div class="item-1-8" v-for="(dayElement, indexDay) in info_page.weekdays">
                                                            {{dayElement.name}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="box-body">
                                                    <div class="group-items" v-if="checkinArray(objectCrud.price_type, [1])">
                                                        <div class="item-line" >
                                                            <div class="item-1-8" >
                                                                <label>{{info_page.price_types.find(item => item._id === objectCrud.price_type).name}}</label>
                                                            </div>


                                                            <div class="item-1-8 box-btn">
                                                                <fg-input type="text"
                                                                          v-model="info_page.base_rate"
                                                                          placeholder=""  @keypress="isNumberKey($event)"  @input="info_page.base_rate = toFixed(info_page.base_rate)">
                                                                </fg-input>
                                                                <button class="add-item" @click="addArray('price_reservation', 'base_rate')">  <i class="el-icon-d-arrow-right"></i></button>
                                                            </div>
                                                            <div class="item-1-8" v-for="(dayElement, indexDay) in info_page.weekdays">
                                                                <fg-input type="text"
                                                                          style="width: 100%"
                                                                          v-model="objectCrud.price_reservation[indexDay]"
                                                                          :disabled="!dayElement.status" @keypress="isNumberKey($event)"  @input="objectCrud.price_reservation[indexDay] = toFixed(objectCrud.price_reservation[indexDay])"
                                                                          placeholder="">
                                                                </fg-input>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="group-items" v-if="checkinArray(objectCrud.price_type, [3,5])">
                                                        <div class="item-line" >
                                                            <div class="item-1-8" >
                                                                <label>{{info_page.price_types.find(item => item._id === objectCrud.price_type).name}}</label>
                                                            </div>

                                                            <div class="item-1-8 box-btn">
                                                                <fg-input type="text"
                                                                          v-model="info_page.base_rate"
                                                                          placeholder="" @keypress="isNumberKey($event)"  @input="info_page.base_rate = toFixed(info_page.base_rate)">
                                                                </fg-input>
                                                                <button class="add-item" @click="addArray('price_room', 'base_rate')">  <i class="el-icon-d-arrow-right"></i></button>
                                                            </div>
                                                            <div class="item-1-8" v-for="(dayElement, indexDay) in info_page.weekdays">
                                                                <fg-input type="text"
                                                                          style="width: 100%"
                                                                          v-model="objectCrud.price_room[indexDay]"
                                                                          :disabled="!dayElement.status"  @keypress="isNumberKey($event)"  @input="objectCrud.price_room[indexDay] = toFixed(objectCrud.price_room[indexDay])"
                                                                          placeholder="">
                                                                </fg-input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="group-items" v-if="checkinArray(objectCrud.price_type, [2,4])">
                                                        <div class="item-line" >
                                                            <div class="item-1-8" >
                                                                <el-checkbox :disabled="true">Adulto</el-checkbox>
                                                            </div>
                                                            <div class="item-1-8 box-btn">
                                                                <fg-input type="text"
                                                                          v-model="info_page.base_rate"
                                                                          placeholder=""  @keypress="isNumberKey($event)"  @input="info_page.base_rate = toFixed(info_page.base_rate)">
                                                                </fg-input>
                                                                <button class="add-item" @click="addArray('price_adult', 'base_rate')">  <i class="el-icon-d-arrow-right"></i></button>
                                                            </div>
                                                            <div class="item-1-8" v-for="(dayElement, indexDay) in info_page.weekdays">
                                                                <fg-input type="text"
                                                                          style="width: 100%"
                                                                          v-model="objectCrud.price_adult[indexDay]"
                                                                          :disabled="!dayElement.status"   @input="objectCrud.price_adult[indexDay] = toFixed(objectCrud.price_adult[indexDay])"
                                                                          placeholder="">
                                                                </fg-input>
                                                            </div>
                                                        </div>
                                                        <div class="item-line" >
                                                            <div class="item-1-8" >
                                                                <el-checkbox v-model="info_page.headersTable[2].status">Junior</el-checkbox>
                                                            </div>
                                                            <div class="item-1-8 box-btn">
                                                                <fg-input type="text"
                                                                          v-model="info_page.base_jr"
                                                                          placeholder=""   @keypress="isNumberKey($event)"  @input="info_page.base_jr = toFixed(info_page.base_jr)">
                                                                </fg-input>
                                                                <button class="add-item" @click="addArray('price_jr', 'base_jr')">  <i class="el-icon-d-arrow-right"></i></button>
                                                            </div>
                                                            <div class="item-1-8" v-for="(dayElement, indexDay) in info_page.weekdays">
                                                                <fg-input type="text"
                                                                          style="width: 100%"
                                                                          v-model="objectCrud.price_jr[indexDay]"    @input="objectCrud.price_jr[indexDay] = toFixed(objectCrud.price_jr[indexDay])"
                                                                          :disabled="!info_page.headersTable[2].status || !dayElement.status"
                                                                          placeholder="" >
                                                                </fg-input>
                                                            </div>
                                                        </div>
                                                        <div class="item-line" >
                                                            <div class="item-1-8" >
                                                                <el-checkbox v-model="info_page.headersTable[1].status">Niño</el-checkbox>
                                                            </div>
                                                            <div class="item-1-8 box-btn">
                                                                <fg-input type="text"
                                                                          v-model="info_page.base_child"
                                                                          placeholder=""   @keypress="isNumberKey($event)"  @input="info_page.base_child = toFixed(info_page.base_child)">
                                                                </fg-input>
                                                                <button class="add-item" @click="addArray('price_children', 'base_child')">  <i class="el-icon-d-arrow-right"></i></button>
                                                            </div>
                                                            <div class="item-1-8" v-for="(dayElement, indexDay) in info_page.weekdays">
                                                                <fg-input type="text"
                                                                          style="width: 100%"
                                                                          v-model="objectCrud.price_children[indexDay]"    @input="objectCrud.price_children[indexDay] = toFixed(objectCrud.price_children[indexDay])"
                                                                          :disabled="!info_page.headersTable[1].status || !dayElement.status"
                                                                          placeholder="">
                                                                </fg-input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="box-response">
                                                        <label class="text-danger" v-if="errores.price">Es necesario completar los campos de tarifa</label>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </div>



                                <div class="form-send" v-show="info_page.index_page===1">
                                    <div class="row">
                                        <div class="col-md-12 mt-3 mb-2">
                                            <div class="div">
                                                <span class="text-danger fllw" v-for="error_is in errores_custom" v-text="'*'+error_is"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-button mb-4">
                                        <button type="button" class="add-item btn-success"  @click="sendForm(info_page.action)" > {{info_page.button}}</button>
                                        <button type="button" class="add-item second btn-danger"  @click="cancelAction()">Cancelar</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>




                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import swal from 'sweetalert2'
    import {Breadcrumb, BreadcrumbItem, Radio, Switch as LSwitch} from 'src/components/index'
    import {Select, Option, DatePicker, TimeSelect, TimePicker, Input,  Upload, Dialog, TabPane, Tabs, Switch, Checkbox} from 'element-ui'
    import Editor from '@tinymce/tinymce-vue';
    //import users from 'src/pages/Dashboard/Tables/users'
    import moment from 'moment';
    import PropertyService from '../../../js/services/PropertyService';
    import CrudService from '../../../js/services/AddonService';
    import RateplanFunctions from '../../../js/functions/RateplanFunctions';
    import ContractService from '../../../js/services/ContractService';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    import Settings from "src/js/helpers/Settings";
    let settings = new Settings();
    import EventBus from "../../../js/helpers/EventBus";
    import {vueTopprogress} from 'vue-top-progress'
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    const contractService = new ContractService();
    const crudService = new CrudService();
    const rateplanFuntions = new RateplanFunctions;
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    const singleName = 'addon';
    const pluralName = singleName+'s';

    const routeCreate = 'CreateAddon';
    const routeEdit = 'EditAddon';
    const routeIndex = 'Addons';

    import {mapGetters} from "vuex";

    export default {
        components: {
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
            [TimePicker.name]: TimePicker,
            [Upload.name]: Upload,
            [Dialog.name]: Dialog,
            [Checkbox.name]:Checkbox,
            [Tabs.name]: Tabs,
            [Switch.name]: Switch,
            [TabPane.name]: TabPane,
            'tinymce-editor': Editor,
            Breadcrumb,
            BreadcrumbItem,
            vueTopprogress,
            [Option.name]: Option,
            [Select.name]: Select,
            LSwitch,
            Radio
        },
        computed: {
            ...mapGetters(["currentUser","selected_property"]),
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                activeErrors: false,
                screenLoading: false,
                errores_custom: [],
                formImcompleted: false,
                dialogImageUrl: '',
                dialogVisible: false,
                breadcrumbs: [
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t(singleName+'.index.title'),
                        path: '/admin/'+pluralName,
                        type: '',
                    },

                    {
                        name: this.$t(singleName+'.new.title'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page: {
                    original_info:{},
                    section_name: 'addon',
                    property: null,
                    rateplans: [],
                    jrs: false,
                    children: false,
                    action: 'add',
                    header: this.$t(singleName+'.new.header'),
                    button:  this.$t(singleName+'.new.button'),
                    property_id: {},
                    index_page: 1,
                    weekdays: [
                        {
                        _id: 1,
                        name: 'Lunes',
                        status: false,
                    },{
                        _id: 2,
                        name: 'Martes',
                        status: false,
                    },{
                        _id: 3,
                        name: 'Miércoles',
                        status: false,
                    },{
                        _id: 4,
                        name: 'Jueves',
                        status: false,
                    },{
                        _id: 5,
                        name: 'Viernes',
                        status: false,
                    },{
                        _id: 6,
                        name: 'Sábado',
                        status: false,
                    },{
                        _id: 0,
                        name: 'Domingo',
                        status: false,
                    }],
                    headersTable: [
                        {
                            _id: 'adult',
                            name:'Adultos',
                            status: true,
                        },
                        {
                            _id: 'child',
                            name:'Niños',
                            status: false,
                        },
                        {
                            _id: 'jr',
                            name:'Juniors',
                            status: false,
                        },
                        {
                            _id: 'room',
                            name:'Por habitación',
                            status: false,
                        },
                        {
                            _id: 'reservation',
                            name:'Por reservación',
                            status: false,
                        }
                        ],
                    price_types:[
                        {
                            _id: 1,
                            name: 'Por reservación'
                        },
                        {
                            _id: 2,
                            name: 'Por persona'
                        },
                        {
                            _id: 3,
                            name: 'Por habitación'
                        },
                        {
                            _id:4,
                            name: 'Por persona por noche'
                        },
                        {
                            _id:5,
                            name: 'Por habitación por noche'
                        }
                    ],

                    tab_lang: 'es',
                    langs: [
                        {
                            lang: 'es',
                            text: this.$t('locale_es'),
                        },
                        {
                            lang: 'en',
                            text: this.$t('locale_en')
                        }

                    ],

                    /*langs: [{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    }, {
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],*/
                    buttons: {
                        information: false,
                        rateplans: false,
                        name: false,
                    },
                    visible:{
                        information: false,
                        rateplans: false,
                        name: false,
                    },
                    lang_file: 'addon',
                    response_name: 'addons',
                    base_rate: '',
                    base_jr: '',
                    base_child: '',
                },

                objectCrud: {
                    _id: null,
                    name: [],
                    description:[],
                    vigency:  [],
                    service: false,
                    get_service:false,
                    qty: 0,
                  /*  package: false,
                    option_package: false,*/
                    arrive: false,
                    departure: false,
                    minimum_nights: false,
                    nights: 1,
                    price_type: 1,
                    price_reservation: [],
                    price_room: [],
                    price_adult: [],
                    price_children: [],
                    price_jr: [],
                    image: [],
                    status: true,
                    rates: []
                },

                pickerOptions1: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate() - 1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    }

                },
                errores: {
                    content: false,
                    gratitude: false,
                    image: false,
                    vigency: false,
                    rateplans: false,
                    name: false,
                    description: false,
                    price: false,
                    qty: false
                },
                modelValidations: {
                    name_es: {
                        required: true
                    },
                    description_es: {
                        required: true
                    },
                    title_banner: {
                        required: true
                    },
                    text_banner: {
                        required: true
                    },
                    title_content: {
                        required: true
                    },
                    content: {
                        required: true
                    },
                    title_gratitude: {
                        required: true
                    },
                    gratitude: {
                        required: true
                    },
                    qty: {
                        required: true
                    },
                },

            }
        },
        created() {
            this.chargerItems(this.selected_property._id);
            moment.locale('es');
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name===routeEdit){
                        this.$router.push({
                            name: routeIndex
                        });
                    }if(this.$route.name===routeCreate){
                        this.chargerItems(property_id)
                    }
                })
            },

            async getInfoProperty(property_id){
                //console.log('this.selected_property', this.selected_property);
                this.info_page.property = this.selected_property;
                //propertyResponse = propertyResponse.data.data.property;
                //return propertyResponse.data.data.property;age_jr
            },

            async chargerItems(property_id = null) {
                this.info_page.property_id = property_id;
                this.info_page.index_page = 1;
                this.initLoading();
                this.getInfoProperty(property_id)
                let date_ = moment().locale('es');
                this.info_page.langs.forEach((itemLang, keyLang ) => {
                    this.objectCrud.name.push({
                        lang: itemLang.lang,
                        text: '',

                    })
                    this.objectCrud.description.push({
                        lang: itemLang.lang,
                        text: '',

                    })
                })

                this.configLogs(property_id, this.info_page.section_name);
                this.objectCrud.property_id = property_id;
                if(this.$route.name===routeEdit){
                    let object_id = this.$route.params.id;
                    this.info_page.action = 'edit';
                    this.log_page.action = 1;
                    this.info_page.header = this.$t(singleName+'.edit.header');
                    this.info_page.button = this.$t(singleName+'.edit.button');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t(singleName+'.edit.header');
                    this.fillersDays();
                    this.updateData(object_id);

                }else{
                    this.fillersDays();
                    setTimeout(()=>{
                        this.closeLoading();
                    }, 500)
                }

            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            fillersDays(typeIs){

                let daysEmptys = [0,0,0,0,0,0,0];
                if(typeIs==='edit'){
                    let price_type = this.objectCrud.price_type;
                    if(this.checkinArray(price_type, [1])){
                        this.objectCrud.price_reservation.push(this.objectCrud.price_reservation.shift());
                    }
                    if(this.checkinArray(price_type, [2, 4])){
                        console.log('checkinArray');
                        this.objectCrud.price_adult.push(this.objectCrud.price_adult.shift());

                        this.objectCrud.price_jr.push(this.objectCrud.price_jr.shift());
                        this.objectCrud.price_children.push(this.objectCrud.price_children.shift());
                    }
                    if(this.checkinArray(price_type, [3, 5])){
                        this.objectCrud.price_room.push(this.objectCrud.price_room.shift());
                    }
                }else{
                    this.objectCrud.price_reservation = [...daysEmptys];
                    this.objectCrud.price_room = [...daysEmptys];
                    this.objectCrud.price_adult = [...daysEmptys];
                    this.objectCrud.price_jr = [...daysEmptys];
                    this.objectCrud.price_children = [...daysEmptys];
                }

            },

            checkinArray(item, arrayItems){


                return (arrayItems.indexOf(item)>=0)
            },

            async updateData(elementID){
                let objectInfo = await crudService.getById(elementID);
                let data = objectInfo.data.data[singleName];
                this.info_page.original_info = {...data}

                //console.log('data', data);
                if (data.from !== null) {
                    data.from = moment(data.from, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
                    data.to = moment(data.to, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
                    data.vigency = [data.from, data.to]
                    delete data.from;
                    delete data.to;
                }
                /*data.image.push({
                    _id: data.image,
                    name: data.image,
                    url: data.image,
                    onServer: true,
                });*/

                console.log('data', data);
                for (let prop in data) {


                    if(prop === 'image'){
                        if(data[prop]!==null){
                            this.objectCrud[prop].push({
                                _id: data[prop],
                                name: data[prop],
                                url: data[prop],
                                onServer: true
                            })
                        }

                        if(prop === 'image' ){
                            this.dialogImageUrl = data[prop];
                        }
                    }
                    else if (prop==='name'){
                            this.objectCrud.name.map(item => item.text = data.name.find(element => element.lang === item.lang).text)
                    }
                    else if (prop==='description'){
                        this.objectCrud.description.map(item => item.text = data.description.find(element => element.lang === item.lang).text)
                    }
                    else{
                        if(prop === 'price_children'){
                            this.info_page.headersTable[1].status = (data[prop].length &&  data[prop].some(item=> item>0));
                        }
                        if(prop === 'price_jr'){
                            this.info_page.headersTable[2].status = (data[prop].length &&  data[prop].some(item=> item>0));
                        }
                        this.objectCrud[prop] = data[prop];
                    }




                }

                //this.objectCrud._id = elementID;
                this.fillersDays('edit');
                this.checkCalendar();
                this.closeLoading();
            },

            createObjectQuick(value, text, type, min_value = 0){
                return  {
                    value,
                    text,
                    type,
                    min_value
                }
            },
            checkCalendar(){

                    this.info_page.weekdays.map(item=> item.status= false);
                    let startDate = moment(this.objectCrud.vigency[0], 'YYYY-MM-DD');
                    let endDate = moment(this.objectCrud.vigency[1], 'YYYY-MM-DD');
                    let numberDays = startDate.diff(endDate, 'days')*(-1);


                    //console.log('numberDays', numberDays);
                if(numberDays>6){
                    this.info_page.weekdays.map(item=> item.status= true);
                }else{
                    while (startDate < endDate) {
                        //console.log('number: ', (startDate.day()));
                        //responseRequest.map(element => element.name =  element.name.find(item=> item['es']).es);
                        this.info_page.weekdays.find(item => item._id === startDate.day()).status = true;

                        startDate.add(1, 'days');
                    }
                }
            },

            checkDaysAvailables() {
                //console.log('checkDaysAvailables')
                let dates_Array = this.promotion.travel_selected;
                let array_aux = [];

                dates_Array.forEach(item => {

                    let result = array_aux.indexOf(moment(item).day());
                    if (result < 0) {
                        array_aux.push(moment(item).day())
                    }
                })

                this.calendar_info.availables_days = array_aux.sort()

                let avai_days = this.calendar_info.availables_days;
                let ky_sunday = avai_days.indexOf(0)
                if (ky_sunday >= 0) {
                    avai_days.splice(ky_sunday, 1);
                    avai_days.push(0);
                }
                //console.log('this.calendar_info.availables_days', this.calendar_info.availables_days)

            },


            validadateForm(){
                let arrayRespuesta = [];
                let arrayToValidate = [];
                let errorResponse = false;
                let objectCrud = this.objectCrud;
                /*let promotion = {...this.promotion};




                arrayToValidate.push(this.createObjectQuick(promotion.booking_time[0], 'Hora de inicio antelación', 'string', 0))
                arrayToValidate.push(this.createObjectQuick(promotion.booking_time[1], 'Hora de fin antelación', 'string', 0))
                //arrayToValidate.push(this.createObjectQuick(promotion.free_night, 'Noche gratis', 'number', 1))
                //let promotion_info = this.promotion_info;*/
                arrayRespuesta = this.fieldsValidate(arrayToValidate);
                console.log('array respuesta:', arrayRespuesta);
                this.errores_custom = arrayRespuesta.fields;
                return !arrayRespuesta.status;

            },


            fieldsValidate(fields){
                let booleanStatus = false;
                let arrayResponses = [];
                fields.forEach(field_info=>{
                    let field = field_info.value;
                    let text = field_info.text;
                    let type = field_info.type;
                    let min_value = field_info.min_value;
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                            arrayResponses.push('El campo '+text+' es requerido');
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                            arrayResponses.push('El campo '+text+' es requerido');
                        }
                        if(parseFloat(field)<min_value){
                            booleanStatus = true ;
                            arrayResponses.push('La cantidad  mínima requerida para '+text+' es: '+min_value );
                        }
                    }
                    if(type==='array'){

                        if(field.length===0){
                            arrayResponses.push('Es necesario agregar un valor agregado');
                            return;
                        }

                        field.forEach(item=>{
                            field = field+'';
                            if(item.name==='null' ||  item.name==='' || item.name.trim().length === 0 ||
                                item.text_es==='null' ||  item.text_es==='' || item.text_es.trim().length === 0  ||
                                item.text_en==='null' ||  item.text_en==='' || item.text_en.trim().length === 0  ){
                                booleanStatus = true ;
                                let checkIdx = arrayResponses.findIndex(itemT=> itemT === 'Es necesario completar todos los campos de los valores agregados')

                                console.log(checkIdx, arrayResponses);

                                if(checkIdx<0){
                                    arrayResponses.push('Es necesario completar todos los campos de los valores agregados');
                                }

                                return;
                            }
                        })

                    }
                    if(type==='min_than'){
                        //console.log(parseInt(field), min_value, 'min_than');
                        if(parseInt(field)<=min_value){
                            booleanStatus = true ;
                            arrayResponses.push(text);
                        }
                    }
                    if(type==='min_time'){
                        if(field<=min_value){
                            booleanStatus = true ;
                            arrayResponses.push(text);
                        }
                    }

                });

                return {
                    status: booleanStatus,
                    fields: arrayResponses
                };

            },

            async  sendInfo(){
                console.log(this.validadateForm());
                if(!this.validadateForm()){
                    return
                }

                /*if(this.$route.name==='editPromotion'){
                    await promotionService.updatePromotion(formData).then(response=>{
                       //console.log('response', response)
                        setTimeout(()=>{
                            this.closeLoading();
                        }, 500)
                        this.showSwal(this.$t('promotion.new.msg'));
                    });

                }else{
                    await promotionService.createPromotion(formData).then(response=>{
                       //console.log('response', response)
                        setTimeout(()=>{
                            this.closeLoading();
                        }, 500)
                        this.showSwal(this.$t('promotion.edit.msg'));
                    });
                }*/



            },
            cancelAction(){
                this.$router.push({
                    name: routeIndex
                });
            },

            validadorCustom(fields, type){
                let booleanStatus = false;
                fields.forEach(field=>{
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                        }
                    }
                });

                return booleanStatus;

            },
            formatConverte(date, formatType = null) {
                let date_return = null;

                switch (formatType) {
                    case 'f-month':

                        date_return  =  moment(date, 'YYYY-MM-DD').format('MMMM D[,] YYYY')
                        break;
                    case 'txt':
                        date_return  =  moment(date, 'YYYY-MM-DD').format('D [de] MMMM YYYY')
                        break;
                    case 'time':
                        date_return  =  moment(date).format('HH:mm')
                        break;

                    default:
                        date_return  =  moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        break;
                }
                return date_return;
            },
            showSwal (msg) {

                swal({
                    title: this.$t(singleName+'.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {
                    this.$router.push({
                        name: routeIndex
                    });
                });
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            },
            alertError(title_cust, text_cust){


                swal({
                    title: title_cust,
                    type: 'warning',
                    text: text_cust,
                    confirmButtonText: 'Aceptar',
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill'
                })
            },
            onChangeImage(e, section) {
                //console.log('event', e.target.files)
                if(e.target.files.length){
                    const image = e.target.files[0];
                    //console.log(image, 'image');
                    const reader = new FileReader();
                    //this.room.image[section].img = image
                    reader.readAsDataURL(image);
                    reader.onload = e =>{
                        this.room.image[section].img = e.target.result;
                    };
                    /*switch (section) {
                        case 0:
                            this.room.image.top = image
                            break
                        case 'item_a':
                            this.room.image.item_a = image
                            reader.readAsDataURL(image);
                            reader.onload = e =>{
                                this.room.image.item_a_front = e.target.result;
                            };
                            break;
                        case 'item_b':
                            this.room.image.item_b = image
                            reader.readAsDataURL(image);
                            reader.onload = e =>{
                                this.room.image.item_b_front = e.target.result;
                            };
                            break;
                        case 'item_c':
                            this.room.image.item_c = image
                            reader.readAsDataURL(image);
                            reader.onload = e =>{
                                this.room.image.item_c_front = e.target.result;
                            };
                            break;

                    }*/
                }
            },


            addAttachment(file, fileList){
                console.log('test add', file.file, fileList);

                const image = file.file;
                const reader = new FileReader();
                //this.room.image[section].img = image
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.objectCrud.image = [];
                    this.objectCrud.image.push( {
                        _id: '',
                        name: image.name,
                        url: e.target.result,
                        onServer: false
                    });
                    this.dialogImageUrl = e.target.result;
                    this.dialogVisible = true;
                };
            },
            handleRemove(file, fileList) {

                this.landing.image = [];
                /*let idxKey = this.listImgs.findIndex(item => item.uid===file.uid)


                //console.log('RM', idxKey, file, fileList, this.listImgs);
                if(file.onServer){
                    this.imgsBash.push(file._id);
                }
                if(idxKey>=0){
                    this.listImgs.splice(idxKey, 1);
                }*/
            },
            handlePictureCardPreview(file) {
                /*this.dialogImageUrl = file.url;
                this.dialogVisible = true;*/
            },
            submitUpload() {
                //console.log( this.$refs.upload);
            },
            onBeforeUpload(file)
            {
                const isIMAGE = file.type === 'image/jpeg'||'image/gif'||'image/png';
                const isLt1M = file.size / 1024 / 1024 < 2;

                if (!isIMAGE) {
                    swal({
                        type: 'warning',
                        text: 'El archivo de carga solo puede estar en formato de imagen',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                    return isLt1M;
                }
                if (!isLt1M) {
                    swal({
                        type: 'warning',
                        text: 'El tamaño de la imagen no puede exceder 2 MB',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                    return isLt1M;
                }

                //this.objAddItem(this.listImgs, file);
            },
             sendForm(action){
                this.validate(action);
            },
            simulateSend(){


            },
            async updateItem(){

                this.initLoading('Actualizando')





                let params =  JSON.parse(JSON.stringify(this.objectCrud));
                params.to = params.vigency[1];
                params.from = params.vigency[0];
                /*let nameCrud = this.toNumericPairs(params.name),
                    descriptionCrud = this.toNumericPairs(params.description);
                delete params.name;
                delete params.description;


                //console.log(params, nameCrud, descriptionCrud);
                params.name = nameCrud;
                params.description = descriptionCrud;*/

                let price_type = params.price_type;
                if(this.checkinArray(price_type, [1])){
                    params.price_reservation.unshift(params.price_reservation.pop());
                    params.price_room = [];
                    params.price_adult = [];
                    params.price_children = [];
                    params.price_jr = [];
                }
                if(this.checkinArray(price_type, [2, 4])){
                    params.price_adult.unshift(params.price_adult.pop());
                    if(this.info_page.headersTable[2].status){
                        params.price_jr.unshift(params.price_jr.pop());
                    }else{
                        params.price_jr = [];
                    }
                    if(this.info_page.headersTable[1].status){
                        params.price_children.unshift(params.price_children.pop());
                    }else{
                        params.price_children = [];
                    }
                    params.price_room = [];
                    params.price_reservation = [];
                }
                if(this.checkinArray(price_type, [3, 5])){
                    params.price_room.unshift(params.price_room.pop());
                    params.price_reservation = [];
                    params.price_adult = [];
                    params.price_children = [];
                    params.price_jr = [];
                }





                if(params.image[0].onServer === false){
                    let image = this.processImageFile(params.image[0].url, params.image[0].name)
                    let ext = image.type.replace('image/', '');
                    let formData = new FormData();
                    formData.append('file', image, image.name);
                    formData.append('property', this.selected_property.name);
                    
                    let responseImage =  await crudService.createFileImage(formData, '-image');                    
                    params.image = responseImage.data.data.file;
                    let responseRequest =  await crudService.deleteFileImage(this.info_page.original_info.image);
                }else{
                    params.image = params.image[0].url;
                }

                crudService.update(params).then(async response=>{
                    response = response.data.data.addon;
                    let changes = logFunctions.cheackProp(this.info_page.original_info, response, this.info_page.section_name)
                    if(changes.length){
                        await this.createLog(response._id, 1, changes);
                    }
                    this.showSwal(this.$t(singleName+'.edit.msg'));
                    this.closeLoading();
                });
            },


            async saveItem(){

                this.initLoading('Guardando')
                let params =  JSON.parse(JSON.stringify(this.objectCrud));
                params.to = params.vigency[1];
                params.from = params.vigency[0];
                //console.log(params)

                let price_type = params.price_type;
                if(this.checkinArray(price_type, [1])){
                    params.price_reservation.unshift(params.price_reservation.pop());
                    params.price_room = [];
                    params.price_adult = [];
                    params.price_children = [];
                    params.price_jr = [];
                }
                if(this.checkinArray(price_type, [2, 4])){
                    params.price_adult.unshift(params.price_adult.pop());
                    params.price_jr.unshift(params.price_jr.pop());
                    params.price_children.unshift(params.price_children.pop());
                    params.price_room = [];
                    params.price_reservation = [];
                }
                if(this.checkinArray(price_type, [3, 5])){
                    params.price_room.unshift(params.price_room.pop());
                    params.price_reservation = [];
                    params.price_adult = [];
                    params.price_children = [];
                    params.price_jr = [];
                }







                /*let nameCrud = this.toNumericPairs(params.name),
                    descriptionCrud = this.toNumericPairs(params.description);
                delete params.name;
                delete params.description;

                //console.log(params, nameCrud, descriptionCrud);
                params.name = nameCrud;
                params.description = descriptionCrud;*/

                console.log(params);

                //Esto ya estaba comentado
                let image = this.processImageFile(params.image[0].url, params.image[0].name)
                let ext = image.type.replace('image/', '');
                let formData = new FormData();
                    formData.append('file', image, image.name);
                    formData.append('property', this.selected_property.name);
                let responseImage =  await crudService.createFileImage(formData, '-image');
                params.image = responseImage.data.data.file;
                crudService.create(params).then(async response=>{
                    let new_addon = response.data.data.addon;
                    await this.createLog(new_addon._id, 0)
                    this.showSwal(this.$t(singleName+'.new.msg'));
                    this.closeLoading();
                });
            },

            toNumericPairs (params_name) {
                let newObj = [];
                //let entries =  Array.from(params_name);
                for (let prop in params_name) {
                    let arrayEmpty = {};
                    arrayEmpty[prop]= params_name[prop];
                    newObj.push(arrayEmpty)
                }

                //params_name =

                return newObj;
            },


            /*------------------------------Validaciones------------------------*/
            getError(fieldName) {
                return this.errors.first(fieldName);
            },
            validate(action) {
                // console.log('action?', action, this.customValidity())
                this.customValidity();
                this.$validator.validateAll().then(isValid => {
                    //console.log('isValid?', isValid)
                    if (isValid) {
                        //console.log('this.customValidity()?', this.customValidity())
                        if (this.customValidity()) {
                            if (action === 'add') {
                                this.saveItem();
                            } else {
                                this.updateItem();
                            }
                        }

                    }
                })
            },

            customValidity() {
                let error_response = true;
                let objectCrud = this.objectCrud;
                let errores = this.errores;

                errores.image = false;
                errores.vigency = false;
                errores.name = false;
                errores.description = false;
                errores.price = false;
                errores.qty = false;

                let baseName = objectCrud.name.find(item=> item.lang === 'es')
                let baseDescription = objectCrud.description.find(item=> item.lang === 'es')

                if(baseName.text==='null' ||  baseName.text==='' || baseName.text.trim().length === 0 ){
                    error_response = false;
                    errores.name = true;
                }
                if(baseDescription.text==='null' ||  baseDescription.text==='' || baseDescription.text.trim().length === 0 ){
                    error_response = false;
                    errores.description = true;
                }

                if(objectCrud.image.length === 0){
                    error_response = false;
                    errores.image = true;
                }
                if (objectCrud.vigency === null || objectCrud.vigency.length < 2 || objectCrud.vigency[0] === null || objectCrud.vigency[1] === null || (objectCrud.vigency[0]===objectCrud.vigency[1])) {
                    error_response = false;
                    errores.vigency = true;
                }
                if(isNaN(parseFloat(objectCrud.qty)) || !isFinite(objectCrud.qty) || objectCrud.qty<1){
                    error_response = false;
                    errores.qty = true;
                }



                //this.info_page.weekdays.map(item=> item.status= false);
                let startDate = moment(this.objectCrud.vigency[0], 'YYYY-MM-DD');
                let endDate = moment(this.objectCrud.vigency[1], 'YYYY-MM-DD');
                let numberDays = startDate.diff(endDate, 'days')*(-1);
                let array_check = [];
                let array_check_child = [];
                let array_check_jr = [];

                if(objectCrud.price_type === 1){
                    array_check = objectCrud.price_reservation;

                }
                if(this.checkinArray(objectCrud.price_type, [3,5])){
                    array_check = objectCrud.price_room;

                }
                if(this.checkinArray(objectCrud.price_type, [2,4])){
                    array_check = objectCrud.price_adult;
                    if(this.info_page.headersTable[1].status){
                        array_check_child = objectCrud.price_children;
                    }
                    if(this.info_page.headersTable[2].status){
                        array_check_jr = objectCrud.price_jr;
                    }

                }

                //console.log('numberDays', numberDays);
                if(numberDays>6){
                    if(!array_check.every(item => item>0)){
                        error_response = false;
                        errores.price = true;
                    }
                    if(this.checkinArray(objectCrud.price_type, [2,4])){
                        if(this.info_page.headersTable[1].status && !array_check_child.every(item => item>0)){
                            error_response = false;
                            errores.price = true;
                        }
                        if(this.info_page.headersTable[2].status && !array_check_jr.every(item => item>0)){
                            error_response = false;
                            errores.price = true;
                        }
                    }

                }else{

                    //console.log('algunos no paso');
                    let getActives = this.info_page.weekdays;

                    //console.log('getActives', getActives);
                    getActives.forEach((element, idx)=>{
                        if(element.status){
                            //let field_value = array_check[idx];
                            let field_values = [];
                            field_values.push(array_check[idx])
                            if(this.checkinArray(objectCrud.price_type, [2,4])){
                                if(this.info_page.headersTable[1].status){
                                    field_values.push(array_check_child[idx]);
                                }
                                if(this.info_page.headersTable[2].status){
                                    field_values.push(array_check_jr[idx]);
                                }
                            }
                            field_values.forEach(field_value=>{
                                if(isNaN(parseFloat(field_value)) || !isFinite(field_value) || field_value<1){
                                    error_response = false;
                                    errores.price = true;
                                }
                            })
                        }
                    })
                }

                return error_response;
            },


            processImageFile(data, filename) {

                let arr = data.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                console.log('data', mime, bstr);

                while (n) {
                    u8arr[n-1] = bstr.charCodeAt(n-1);
                    n -= 1;
                }

                return new File([u8arr], filename, { type: mime })
            },
            handleClick(tab, event) {
                //console.log(tab, event);
            },
            addArray(arrayName, itemName){
                //let arrayElement = this.objectCrud[arrayName];
                let baseValue = parseInt(this.info_page[itemName]);
                /*if(!arrayElement.every(item => item == baseValue)){
                    let elementIs = this.findNotIndex(arrayElement, baseValue)
                    this.$set(this.objectCrud[arrayName],  elementIs, baseValue);
                }*/
                console.log(this.objectCrud[arrayName], 'objectCrud');
                if(this.objectCrud[arrayName].length === 0){
                    this.objectCrud[arrayName] = [0,0,0,0,0,0,0];
                }

                this.objectCrud[arrayName] = this.objectCrud[arrayName].map(item=> item = baseValue);
                //console.log('this.objectCrud[arrayName]', this.objectCrud[arrayName]);
            },
            findNotIndex(arr, id) {
                let obj = arr.find(o => o != id);
                return  arr.indexOf(obj);
            },
            isNumberKey(event)
            {
                var charCode = event.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    event.preventDefault();;
                } else {
                    return true;
                }
            },

            toFixed(num, fixed) {

                //num = num.replace(/\D/g,'');
                if(isNaN(parseFloat(num)) || !isFinite(num)){
                    num =  num.replace(/[^\d.-]/g, '')
                }
                var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
                //console.log('re', re);
                return num.toString().match(re)[0];

                //Math.round(floatNumber*100)/100;
            },

        },
        mounted() {
            this.changeProperty()
            //this.$refs.topProgress.start();
        }
    }
</script>
<style lang="scss">
    .bg-transparend-modal {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 10;
        background: rgb(2,0,36);
        background: linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.8995973389355743) 150%);
    }
    .minus-aggreates{
        position: relative;
        top: 10px;
        display: inline-block;
        vertical-align: top;
    }
    .custom-error {
        label.text-danger {
            text-transform: none;
            white-space: nowrap;
            font-size: 80%;
        }
        &.mbis-0 {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
    .box-inventory.landings {


        .form-updates, .form-dates{
            position: relative;
            .resumen-item{
                label, span{
                    font-size: 14px;
                }
            }
        }
        span.fllw {
            display: inline-block;
            width: 100%;
        }

        .fw {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            text-transform: none;
            color: #0e0e0e;
        }
        span.btn-link {
            margin-left: 0;
        }

        .group-item {
            &.title{
                label{
                    width: 200px;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: none;
                    color: #0e0e0e;
                }
            }


            .input-default {
                width: 150px;
                display: inline-block;
                color: #0e0e0e;
                margin-bottom: 7px;
            }

            span.btn-link {
                color: #1D62F0;
                display: inline-block;
                font-size: 14px;

            }
            .select-default {
                display: inline-block;
                width: 150px;
                margin-bottom: 7px;
            }
            .large-size{
                width: 200px;

            }

        }

    }

    .line-complete{
        span{
            display: inline-block;
            color: #bf9000;
            font-size: 14px;
            width: auto;
            margin-left: 10px;
        }
        .s-icon {
            font-size: 20px;
            color: #bf9000;
            float: left;
        }
    }
    .box-inventory {
        border: 0;
        .form-send {
            .s-icon {
                font-size: 20px;
                color: #bf9000;
            }
            label {
                display: inline-block;
                color: #bf9000;
                font-size: 14px;
                width: auto;
                margin-left: 10px;
            }
            .box-button {
                button {
                    width: calc(33.3% - 30px);
                    margin-right: 15px;
                }
            }

        }
        .is_title {
            font-size: 16px;
            margin: 0 0 15px;
        }
        .subtitle {
            font-weight: 600;
            margin-bottom: 25px;
        }

        .box-button {
            //padding-left: 10px;
            .add-item {
                width: 100%;
                height: 40px;
                color: #fff;
                background-color: #222222;
                border: 2px solid #000;
                &.inactive {
                    opacity: .2;
                    cursor: default;
                }
            }
        }
        .box-btn{
            //padding-left: 10px;
            .form-group{
                width: calc(100% - 55px);
                display: inline-block;
            }
            input{
                width: 100%;
            }
            .add-item{
                margin-left: 15px;
                width: 40px ;
                height: 40px;
                color: #fff;
                background-color: #6B3E91;
                border: 2px solid #6B3E91;
                border-radius: 30%;
            }
        }

        .w-border {
            border: 1px solid #bebebe;
            padding: 10px 25px;
            margin-bottom: 15px;
        }

        label {
            &.auto-width{
                width: auto;
            }
            color: #000;
            width: 100%;
            display: inline-block;
            font-size: 14px;
            text-transform: unset;
            span {
                font-weight: 400;
                margin-left: 5px;
            }
            span.icon-cont {
                margin-left: 0;
            }

        }
        h6 {
            margin-bottom: 15px;
            color: #085394;
            font-size: 18px;
            text-transform: unset;
        }


        .box-head, .box-rate, .box-restrict {
            width: 100%;
            display: inline-block;
        }
        .box-head {
            margin-top: 15px;
            label {
                font-size: 16px;
                margin-bottom: 25px;
            }
        }
        .box-item {
            display: inline-block;
        }

        .form-check .form-check-label {
            text-transform: none;
        }
    }

    .mt-3{
        margin-top: 1rem;
    }
    .input-default.has-error input, .el-date-editor.has-error, .input-default.has-error .select-default.el-select .el-input input, .input-default.has-error textarea, .has-error .el-range-editor{
        border-color: #ff4500 !important;
        color: #ff4500!important;;
    }
    label.year-header{
        font-size: 22px;
        font-weight: 600;
    }
    .el-upload{
        width: 100%;
        .el-upload-dragger{
            width: 100%;
            img{
                border-radius: 0;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .el-upload-list__item-name {
        padding-left: 20px;
    }
    .box-headers{
        text-align: center;
        padding-bottom: 15px;
    }
    .item-line{
        width: 100%;
        display: inline-block;

        .item-1-8{
            width: calc(100% / 9);
            display: inline-block;
            padding: 0 10px;

        }
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label,  .el-checkbox__input+span.el-checkbox__label, .el-checkbox__input.is-checked+.el-checkbox__label{
        color: #0e0e0e;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner{
        background-color: #6B3E91;
        border-color: #6B3E91;
    }
</style>
